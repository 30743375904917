import React, { useRef, useState } from 'react'
import "./Home.css";
import { NavLink } from 'react-router-dom';

const Home = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});


    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.email = ""; errors.massage = "";

            var url = "https://wa.me/1271379270?text="
                + " Name : " + name + " Email : " + email + " Content : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        alert("Thank you! ")
        setName("");
        setEmail("");
        setMassage("");
    }


    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }
        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Massage Is Requried";
        }
        return errors;
    }

    return (
        <>

            <section>
                <div className="container-fluid p-0 " >
                    <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" >
                        <div className="carousel-inner FontFamilyMontSerret">
                            <div className="carousel-item active  Same-Img-Size" style={{ backgroundImage: `url(${require("../img/Silder1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '100%' }}>
                            </div>

                            <div className="carousel-item Same-Img-Size " style={{ backgroundImage: `url(${require("../img/Silder3.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '100%' }}>
                            </div>

                            <div className="carousel-item Same-Img-Size " style={{ backgroundImage: `url(${require("../img/Silder4.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '100%' }}>
                            </div>

                            <div className="carousel-item Same-Img-Size " style={{ backgroundImage: `url(${require("../img/Silder2.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '100%' }}>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg mt-3 mb-3 laila-semibold ProductPadding"  >
                    <div className='mb-4'>
                        <h3 className='mb-3 mt-lg-3 text-center' style={{ color: "#232323", fontWeight: "800", fontSize: "36px" }} >
                            THE BEST PRODUCTS
                            <div className='mt-3 mb-3 d-flex justify-content-center align-items-center' >
                                <div className='' style={{ borderBottom: "4px solid #999", borderRadius: "10px", width: "5%" }}>
                                </div>
                            </div>
                            <h6 className='' style={{ color: "#444", fontWeight: "400", fontSize: "18px", padding: "10px 0px" }} >
                                RECOMMENDED PRODUCTS
                            </h6>
                        </h3>
                    </div>

                    <div className="row align-items-center g-4 p-lg-2 mt-3 laila-semibold"   >
                        <div className="col-xl-3 col-md-4 col-sm-6 mb-lg-4">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100 ' src={require("../img/Product1.png")} alt='works-at-iclodsoft' />
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <h2 className='ProductText' >
                                            SC105 Mycotoxin Aflatoxin M1 Rapid Test Kit(0.5ppb)
                                        </h2>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-xl-3 col-md-4 col-sm-6 mb-lg-4">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100 ' src={require("../img/Product2.png")} alt='works-at-iclodsoft' />
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <h2 className='ProductText' >
                                            SC506 Bovine Cow Cattle Pregnancy Rapid Test Kit
                                        </h2>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-xl-3 col-md-4 col-sm-6 mb-lg-4">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100 ' src={require("../img/Product1.png")} alt='works-at-iclodsoft' />
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <h2 className='ProductText' >
                                            SC156 Cow Milk/ Spiramycin Rapid Test Kit for Milk
                                        </h2>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-xl-3 col-md-4 col-sm-6 mb-lg-4">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100 ' src={require("../img/Product1.png")} alt='works-at-iclodsoft' />
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <h2 className='ProductText' >
                                            SC196 Beta-lactams Trimethoprim Rapid Test Kit
                                        </h2>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-xl-3 col-md-4 col-sm-6 mb-lg-4">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100 ' src={require("../img/Product1.png")} alt='works-at-iclodsoft' />
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <h2 className='ProductText' >
                                            SC175 Lincomycin and Tilmicosin Combo Test
                                        </h2>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-xl-3 col-md-4 col-sm-6 mb-lg-4">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100 ' src={require("../img/Product1.png")} alt='works-at-iclodsoft' />
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <h2 className='ProductText' >
                                            SC066 tetracyclines Stremptomycin Chloramphenicol Rapid Test Kit
                                        </h2>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-xl-3 col-md-4 col-sm-6 mb-lg-4">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100 ' src={require("../img/Product1.png")} alt='works-at-iclodsoft' />
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <h2 className='ProductText' >
                                            SC150 Gentamycin Kanamycin Streptomycin Rapid Test Kit
                                        </h2>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-xl-3 col-md-4 col-sm-6 mb-lg-4">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100 ' src={require("../img/Product1.png")} alt='works-at-iclodsoft' />
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <h2 className='ProductText' >
                                            SC144 Bacitracin and Colistin and Metronidazole Rapid Test Kit
                                        </h2>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg ProductPadding">
                    <div className="row d-flex justify-content-around align-items-center">
                        <div className="col-xl-6">
                            <img className='w-100 ' src={require("../img/AboutSection.png")} alt='works-at-iclodsoft' />
                        </div>

                        <div className="col-xl-6">
                            <div className='AboutSection'>
                                <h2>
                                    About Watar
                                </h2>
                                <p>
                                    Shaanxi Watar Biotechnology Co.,Ltd was founded by a group of experienced professionals in food / feed / animal disease diagnostics field in Xi'an(the start point of the”Silk Road”). Watar's main business is to provide ELISA Test Kit and Rapid Test Strip for food / feed safety testing and animal disease diagnostics, most of the products have CE certificates. The hot products like SC036 beta-lactams & tetracyclines 2 Sensor Test Kit ...
                                </p>
                                <NavLink className='text-decoration-none' to='/'>
                                    <button className='text-center'>
                                        Read more
                                    </button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg mt-4 mb-4 pb-lg-5 laila-semibold">
                    <div className='mb-4 pt-lg-4'>
                        <h3 className='mb-3 mt-lg-3 text-center' style={{ color: "#232323", fontWeight: "800", fontSize: "36px" }} >
                            WHY CHOOSE US
                            <div className='mt-3 mb-3 d-flex justify-content-center align-items-center' >
                                <div className='' style={{ borderBottom: "4px solid #999", borderRadius: "10px", width: "5%" }}>
                                </div>
                            </div>
                            <h6 className='' style={{ color: "#444", fontWeight: "400", fontSize: "18px", padding: "8px 0px" }} >
                                Why choose us
                            </h6>
                        </h3>
                    </div>

                    <div className="row align-items-center g-4 p-lg-2 mt-3  laila-semibold"   >
                        <div className="col-xl-3 col-sm-6">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/WhyChoseSection1.png")} alt='works-at-iclodsoft' />
                                </div>
                                <div>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <h2 className='WhyChooseText' >
                                            Strong R&D
                                        </h2>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-xl-3 col-sm-6">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/WhyChoseSection2.png")} alt='works-at-iclodsoft' />
                                </div>
                                <div>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <h2 className='WhyChooseText' >
                                            Strict QC
                                        </h2>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-xl-3 col-sm-6">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/WhyChoseSection3.png")} alt='works-at-iclodsoft' />
                                </div>
                                <div>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <h2 className='WhyChooseText' >
                                            Company Certificated
                                        </h2>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-xl-3 col-sm-6 ">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/WhyChoseSection4.png")} alt='works-at-iclodsoft' />
                                </div>
                                <div>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <h2 className='WhyChooseText' >
                                            24hour Online Service
                                        </h2>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../img/FoodSafety.png")})`, backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }} >
                    <div className="row d-flex align-items-center mt-5">
                        <div className='ServiceSection '>
                            <p>
                                Intelligence for Food / Feed Safety
                            </p>
                            <p>
                                Any question, please feel free to contact us!
                            </p>
                            <NavLink className='text-decoration-none' to='/'>
                                <button className='text-center'>
                                    GET A QUOTE
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../img/CompanyIntro.png")})`, backgroundAttachment: 'local', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }} >
                    <div className="container-lg ProductPadding mt-5">
                        <div className="row g-4 d-flex align-items-center mt-5">
                            <div className="col-xl-3 col-sm-6">
                                <div className='ServiceMain'>
                                    <p>
                                        2012
                                        <span>
                                            AD
                                        </span>
                                    </p>
                                    <span>
                                        Founded
                                    </span>
                                </div>
                            </div>

                            <div className="col-xl-3 col-sm-6">
                                <div className='ServiceMain'>
                                    <p>
                                        430
                                        <span>
                                            Kinds
                                        </span>
                                    </p>
                                    <span>
                                        Products
                                    </span>
                                </div>
                            </div>

                            <div className="col-xl-3 col-sm-6">
                                <div className='ServiceMain'>
                                    <p>
                                        12,000
                                        <span>
                                            ㎡
                                        </span>
                                    </p>
                                    <span>
                                        GMP Factory
                                    </span>
                                </div>
                            </div>

                            <div className="col-xl-3 col-sm-6">
                                <div className='ServiceMain'>
                                    <p>
                                        40
                                        <span>
                                            Countries
                                        </span>
                                    </p>
                                    <span>
                                        Exported to
                                    </span>
                                </div>
                            </div>

                            <div className='d-flex justify-content-center align-items-center'>
                                <div className='ServiceInfo'>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <button className='text-center'>
                                            VIEW COMPANY INTRODUCTION
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg mt-4 mb-4 pb-lg-5 laila-semibold">
                    <div className='mb-4 pt-lg-4'>
                        <h3 className='mb-3 mt-lg-3 text-center' style={{ color: "#232323", fontWeight: "800", fontSize: "36px" }} >
                            LATEST NEWS
                            <div className='mt-3 mb-3 d-flex justify-content-center align-items-center' >
                                <div className='' style={{ borderBottom: "4px solid #999", borderRadius: "10px", width: "5%" }}>
                                </div>
                            </div>
                            <h6 className='' style={{ color: "#444", fontWeight: "400", fontSize: "18px", padding: "8px 0px" }} >
                                Focus on high-tech textile printing and dyeing auxiliaries
                            </h6>
                        </h3>
                    </div>

                    <div className="row g-4 d-flex justify-content-xl-around p-lg-2 mt-3  laila-semibold"   >
                        <div className="col-xl-4 col-sm-6">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='mb-3 pb-lg-3'>
                                    <img className='w-100 pb-lg-1' src={require("../img/LatestNews3.png")} alt='works-at-iclodsoft' />
                                </div>
                                <div className='mt-lg-5 pt-lg-5'>
                                    <NavLink className='text-decoration-none ' to='/'>
                                        <div className='LatestNewsText' >
                                            <h2>
                                                Watar Lanches NEW Alfatoxin
                                                M1 Test Kit 0.03ppb
                                            </h2>
                                            <h6>
                                                24 Hours Standby
                                            </h6>
                                        </div>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-xl-4 col-sm-6">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/LatestNews1.png")} alt='works-at-iclodsoft' />
                                </div>
                                <div>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <div className='LatestNewsText' >
                                            <h2>
                                                How Concerned Should I Be
                                                About Aflatoxins in Peanut
                                                Butter?
                                            </h2>
                                            <h6>
                                                24 Hours Standby
                                            </h6>
                                        </div>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-xl-4 col-sm-6">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='mb-3'>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../img/LatestNews2.png")} alt='works-at-iclodsoft' />
                                </div>
                                <div>
                                    <NavLink className='text-decoration-none' to='/'>
                                        <div className='LatestNewsText' >
                                            <h2>
                                                Organic Pasture’s owner accepts
                                                consent decree in 15-year-old
                                                raw milk case
                                            </h2>
                                            <h6>
                                                24 Hours Standby
                                            </h6>
                                        </div>
                                    </NavLink>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg mt-4  laila-semibold">
                    <div className=' pt-lg-4'>
                        <h3 className='mb-3 mt-lg-3 text-center' style={{ color: "#232323", fontWeight: "800", fontSize: "36px" }} >
                            CONTACT US
                            <div className='mt-3 mb-3 d-flex justify-content-center align-items-center' >
                                <div className='' style={{ borderBottom: "4px solid #999", borderRadius: "10px", width: "5%" }}>
                                </div>
                            </div>
                        </h3>
                    </div>
                </div>

                <div className="container-fluid" style={{ backgroundImage: `url(${require("../img/ContactHome.png")})`, backgroundAttachment: 'local', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "370px", backgroundPosition: "center" }} >
                </div>

                <div className="container-lg p-5">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className='ContactAddress'>
                                <p>
                                    <b>CONTACT US</b>
                                </p>
                                <p>
                                    Phone: +86-18612182039
                                </p>
                                <p>
                                    Tel: +86-029-85274126
                                </p>
                                <p>
                                    Whatsapp: +8618612182039
                                </p>
                                <p>
                                    Email: info@watarbio.com
                                </p>
                                <p>
                                    Add: No. 12, Hongzhuan South Road, Yanta District, Xi’an 710061, China
                                </p>
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <div className='ContactAddress'>
                                <p>
                                    If you are searching for information about our products, please submit your inquiry by filling out the form below and our customer care team will make a reply.
                                </p>

                                <p>
                                    <b>
                                        Use the form below to get in touch with us:
                                    </b>
                                </p>
                            </div>

                            <div>
                                <form ref={form} onSubmit={loginHandle} className="row g-4 mt-3 laila-semibold" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                    <div class="col-lg-12 ">
                                        <input type="text" placeholder='Name' className="form-control   text-dark   " style={{ borderRadius: "0px 0px 0px 0px", color: "#777", backgroundColor: "#fff", border: "1px solid #ccc", height: "45px" }} id="inputName" name="name"
                                            onChange={e => setName(e.target.value)} value={name}
                                        />
                                        {errors.name && <div className='text-danger'>{errors.name}</div>}
                                    </div>

                                    <div className='col-lg-12'>
                                        <input type="email" placeholder='Email' className="form-control boderRadius " style={{ borderRadius: "0px 0px 0px 0px", color: "#777", backgroundColor: "#fff", border: "1px solid #ccc", height: "45px" }} id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='text-danger'>{errors.email}</div>}
                                    </div>

                                    <div className="col-lg-12">
                                        <textarea type="text" placeholder='Content' className="form-control " style={{ borderRadius: "0px 0px 0px 0px", height: "105px", backgroundColor: "#fff", border: "1px solid #ccc" }} id="inputText3" name="message"
                                            onChange={e => setMassage(e.target.value)} value={massage}
                                        />
                                        {errors.massage && <div className='text-danger'>{errors.massage}</div>}
                                    </div>

                                    <div className="col-lg-12 mt-lg-4 pt-4">
                                        <button type="submit" className=" p-1 SendButton "  ><b className='' style={{ fontWeight: "600", fontSize: "18px" }}>SUBMIT</b></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </>
    )
}

export default Home