import React from 'react'
import "./Navbar.css"
import { NavLink } from 'react-router-dom'

const Navbar = () => {
    return (
        <>

            <section>
                <div className='container-fluid d-none d-sm-block' style={{ backgroundColor: "#33A69A" }}>
                    <div className="container-lg laila-semibold">
                        <div className="row d-flex  align-items-center text-white ">
                            <div className="col-lg-12 col-md-12">
                                <div className='d-none d-sm-block'>
                                    <ul className='d-flex justify-content-between align-items-center mb-0 p-2' >
                                        <NavLink className='text-decoration-none ' to='tel:9921792104'>
                                            <li className='laila-semibold text-white' style={{ listStyle: "none", fontSize: "12px" }} > Welcome: Shaanxi Watar Biotechnology Co.,Ltd </li>
                                        </NavLink>
                                        {/* <NavLink className='text-decoration-none' to='mailto:pashumitraa@gmail.com'>
                                            <li className='laila-semibold text-white' style={{ listStyle: "none", fontSize: "12px" }}>  pashumitraa@gmail</li>
                                        </NavLink> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-sm-none d-block' style={{ backgroundColor: "#33A69A", borderBottom: "1px solid #f1f1f1" }}>
                    <div className="row  pt-3 pb-2 p-2 d-flex justify-content-around align-items-center text-white laila-semibold">
                        <div>
                            <NavLink className='text-decoration-none ' to='tel:9921792104'>
                                <li className='laila-semibold text-white' style={{ listStyle: "none", fontSize: "12px" }} > Welcome: Shaanxi Watar Biotechnology Co.,Ltd </li>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0 d-none d-xl-block d-block" style={{ backgroundColor: "#fff" }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar p-0 "   >
                            <div className='navbar-brand mt-1'>
                                <NavLink to="/">
                                    <img className='' src={require("../img/Logo.png")} alt="icloudsoft_logo" />
                                </NavLink>
                            </div>
                            <button className="navbar-toggler me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars "    ></i>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav"  >
                                    <li className="nav-item dropdown pe-lg-2">
                                        <a className="nav-link " aria-current="page" to="/">Home</a>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-2">
                                        <a className="nav-link " role="button" data-bs-toggle="dropdown" aria-expanded="false"  >
                                            About Watar
                                        </a>
                                        <ul className="dropdown-menu"  >
                                            <li><NavLink className="dropdown-item" to="/all"> All </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/hospitality"> Hospitality </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/adaptive"> Adaptive Re-Use </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/institutional"> Institutional </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/culture"> Culture </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/mixeduse"> Mixed Use </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/homes"> Homes </NavLink></li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-2">
                                        <a className="nav-link " role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Milk Test Kit
                                        </a>
                                        <ul className="dropdown-menu"   >
                                            <li><NavLink className="dropdown-item" to="/interiorsall"> All </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/retail"> Retail </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/interiorsadaptivereuse"> Adaptive Re-Use </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/interiorsinstitutional"> Institutional </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/interiorsworkplace"> Workplace </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/interiorsleisure"> Leisure </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/interiorshomes"> Homes </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/interiorshotels"> Hotels </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/interiorsfb"> F & B </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/brandexperiences"> Brand <br /> Experiences </NavLink></li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-2">
                                        <a className="nav-link  " aria-current="page" to="/">Pet Rapid Test</a>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-2">
                                        <a className="nav-link  " aria-current="page" to="/">Grain Test Kit</a>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-2">
                                        <a className="nav-link  " aria-current="page" to="/">Instrument</a>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-2">
                                        <a className="nav-link  " aria-current="page" to="/">News</a>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-2">
                                        <a className="nav-link  " aria-current="page" to="/">Contact us</a>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-2">
                                        <form class="form-inline">
                                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                                            {/* <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> */}
                                        </form>
                                    </li>
                                    &nbsp;
                                </ul>

                            </div>
                        </nav>
                    </div>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block d-sm-block">
                    <nav className="navbar navbar-expand-lg p-0 " style={{ backgroundColor: "#33a69a" }} >
                        <div className="container-fluid p-0 ">
                            <div className='mt-2 ' style={{ marginLeft: "100px" }}>
                                <NavLink to="/">
                                    <img className='w-100' style={{ height: "6rem", }} src={require("../img/Logo.png")} alt="icloudsoft_logo" />
                                </NavLink>
                            </div>
                            <button className="navbar-toggler me-4" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#fff" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " style={{ backgroundColor: "#33a69a", borderTop: "1px solid #f1f1f1" }} id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start  w-100 p-3 pt-4 pb-5"  >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/contact" > <b className='' style={{fontWeight:"400"}}> Home </b> </NavLink>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle text-white pb-1 " style={{ fontSize: "13.5px" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b className='' style={{fontWeight:"400"}} > About Water </b></div>
                                        <ul className="dropdown-menu nav collapse  " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px",  border: "none" }} id="submenu">
                                            <li className='nav-item'>
                                                <div className='container-fluid'>
                                                    <div className=" text-white">
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily ' to="/interiorsall" >
                                                            <div className='dropdown-item '> ALL </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/retail" >
                                                            <div className='dropdown-item '> RETAIL </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/interiorsadaptivereuse" >
                                                            <div className='dropdown-item'> ADAPTIVE RE-USE </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/interiorsinstitutional" >
                                                            <div className='dropdown-item'> INSTITUTIONAL </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/interiorsworkplace" >
                                                            <div className='dropdown-item '> WORKPLACE  </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/interiorsleisure" >
                                                            <div className='dropdown-item '> LEISURE  </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/interiorshomes" >
                                                            <div className='dropdown-item '> HOMES  </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/interiorshotels" >
                                                            <div className='dropdown-item '> Hotels  </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/interiorsfb" >
                                                            <div className='dropdown-item '> F & B  </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/brandexperiences" >
                                                            <div className='dropdown-item '> Brand Experiences  </div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle text-white pb-1" style={{ fontSize: "13.5px" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b className='' style={{fontWeight:"400"}}> Milk Test Kit </b></div>
                                        <ul className="dropdown-menu nav collapse  " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#F04E23", border: "none" }} id="submenu">
                                            <li className='nav-item'>
                                                <div className='container-fluid'>
                                                    <div className=" text-white">
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily ' to="/newsandviews" >
                                                            <div className='dropdown-item '> ALL </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/featuredin" >
                                                            <div className='dropdown-item '> Featured In </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/awards" >
                                                            <div className='dropdown-item'> Awards </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/blogs" >
                                                            <div className='dropdown-item'> Blogs </div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/events" >
                                                            <div className='dropdown-item '> Events  </div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/contact" > <b className='' style={{fontWeight:"400"}} > Pet Rapid Test </b> </NavLink>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/contact" > <b className='' style={{fontWeight:"400"}} > Grain Test Kit </b> </NavLink>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/contact" > <b className='' style={{fontWeight:"400"}} > Instrument </b> </NavLink>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/contact" > <b className='' style={{fontWeight:"400"}} > News </b> </NavLink>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/contact" > <b className='' style={{fontWeight:"400"}} > Contact us </b> </NavLink>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

        </>
    )
}

export default Navbar