import React from 'react'
import "./Footer.css"
import { NavLink, NavNavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <>

            <section>
                <div className="container-fluid pt-lg-3 " style={{ backgroundColor: "#111" }}>
                    <div className="container laila-semibold pt-lg-4">
                        <div className="row p-lg-3 g-4 d-flex justify-content-xl-center">
                            <div className="col-xl-3 col-md-6 text-white ">
                                <div className='pb-2'>
                                    <NavLink to='main' style={{ cursor: "pointer" }}>
                                        <img className='' style={{ height: "4.5rem" }} src={require("../img/Logo.png")} alt="LogoFooter" title='LogoFooter' />
                                    </NavLink>
                                </div>
                                <div className='mt-2'>
                                    <p className='me-3' style={{ color: "#fff", fontSize: "12px", lineHeight: "2", fontWeight: "400" }} >
                                        COMPANY PROFILE：Shaanxi Watar Biotechnology Co.,Ltd was founded by a group
                                        of experienced professionals in food / feed / animal
                                        disease diagnostics field in Xi'an(the start point of the”Silk Road”)...
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div className="col-xl-12">
                                    <div className="row">
                                        <div className="col-xl-4 col-sm-6 text-white ">
                                            <div className=' ms-lg-3' >
                                                <h6 className='SolutionDropDownFontFamily2 text-decoration-none mt-3 FooterHoverMain' >ABOUT US</h6>
                                            </div>
                                            <div className='pt-lg-1 ms-lg-3'>
                                                <div className='mt-3 fontFamilyHeader1 '>
                                                    <NavLink to='main' className='text-decoration-none FooterHover' > <p className=''>About us</p></NavLink>
                                                    <NavLink to='services' className='text-decoration-none FooterHover' > <p className=''>Contact us</p></NavLink>
                                                    <NavLink to='about' className='text-decoration-none FooterHover' > <p className=''>Download</p></NavLink>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-sm-6 text-white ">
                                            <div className='mb-2 ms-lg-3' >
                                                <h6 className='SolutionDropDownFontFamily2 text-decoration-none mt-3 FooterHoverMain' >NEWS</h6>
                                            </div>
                                            <div className='pt-lg-1 ms-lg-3'>
                                                <div className=' mt-3 fontFamilyHeader1 '>
                                                    <NavLink to='main' className='text-decoration-none FooterHover' > <p className=''>Company news</p></NavLink>
                                                    <NavLink to='services' className='text-decoration-none FooterHover' > <p className=''>Industry news</p></NavLink>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-sm-6 text-white ">
                                            <div className='mb-2 ms-lg-3' >
                                                <h6 className='SolutionDropDownFontFamily2 text-decoration-none mt-3 FooterHoverMain'  >PRODUCTS</h6>
                                            </div>
                                            <div className='pt-lg-1 ms-lg-3'>
                                                <div className='mt-3 fontFamilyHeader1 ' >
                                                    <NavLink to='main' className='text-decoration-none FooterHover' > <p className=''>Milk Test Kits</p></NavLink>
                                                    <NavLink to='services' className='text-decoration-none FooterHover' > <p className=''>Pet Test Kit</p></NavLink>
                                                    <NavLink to='about' className='text-decoration-none FooterHover' > <p className=''>Grain Test Kit</p></NavLink>
                                                    <NavLink to='product' className='text-decoration-none FooterHover' > <p className=''>Honey Test Kit</p></NavLink>
                                                    <NavLink to='contact' className='text-decoration-none FooterHover'> <p className=''>Other Test</p></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 text-white ">
                                <div className='mb-2' >
                                    <h6 className='SolutionDropDownFontFamily2 text-decoration-none mt-3 FooterHoverMain' >CONTACT US</h6>
                                </div>
                                <div className='pt-lg-1'>
                                    <div className='mt-2 fontFamilyHeader1' >
                                        <h4 className='mb-1' style={{ lineHeight: "28px", fontSize: "15.5px" }}> Phone: +86-18612182039 </h4>
                                        <h4 className='mb-1' style={{ lineHeight: "28px", fontSize: "15.5px" }}> Whatsapp: +8618612182039</h4>
                                        <h4 className='mb-1' style={{ lineHeight: "28px", fontSize: "15.5px" }}> Email: info@watarbio.com</h4>
                                        <h4 className='mb-1' style={{ lineHeight: "28px", fontSize: "15.5px" }}> Add: No. 12, Hongzhuan South Road, Yanta District, Xi’an 710061, China</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-flex p-3 justify-content-center align-items-center" style={{ backgroundColor: "#000" }} >
                    <div className='text-center  text-white  ' style={{ fontSize: "15px", fontWeight: "400" }} >
                        Copyright © 2013-2023 Shaanxi Watar Biotechnology Co.,Ltd All Rights Reserved.
                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer